import { useContext, useEffect } from 'react'
import MuiMarkdown from 'mui-markdown'
import Box from '@mui/material/Box'

import { ShellContext } from 'contexts/ShellContext'
import {
  messageTranscriptSizeLimit,
  messageCharacterSizeLimit,
} from 'config/messaging'

import './index.sass'

const messageTranscriptSizeLimitFormatted = Intl.NumberFormat().format(
  messageTranscriptSizeLimit
)

const messageCharacterSizeLimitFormatted = Intl.NumberFormat().format(
  messageCharacterSizeLimit
)

export const About = () => {
  const { setTitle } = useContext(ShellContext)

  useEffect(() => {
    setTitle('about')
  }, [setTitle])

  return (
    <Box className="About max-w-3xl mx-auto p-4">
      <MuiMarkdown>
        {`
### guide

e2e encrypted, decentralized, ephemeral, serverless peer-to-peer communication tool

the URL-accessible public rooms can be custom-named, though the default unguessable names ensures optimal security

private rooms necessitate a mutually agreed password for entry, with mismatched passwords yielding no error due to the lack of a central arbitrating mechanism 

securely share the room URL, for instance, via [burner note](https://burnernote.com/) or [yopass](https://yopass.se/), and receive notifications upon others' entry

in public rooms, new peers automatically request the existing conversation transcript, which evaporates once all peers depart. this backfilling is absent in private rooms

the chat transcript history is confined to ${messageTranscriptSizeLimitFormatted} messages

messages are capped at ${messageCharacterSizeLimitFormatted} characters and support [github-flavored markdown](https://www.markdownguide.org/cheat-sheet/) with code syntax highlighting

dispatch messages with \`enter\` and insert line breaks with \`shift + enter\`
        `}
      </MuiMarkdown>
    </Box>
  )
}
