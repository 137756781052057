import { useContext, useEffect } from 'react'
import Box from '@mui/material/Box'
import MuiMarkdown from 'mui-markdown'

import { ShellContext } from 'contexts/ShellContext'

import './index.sass'

export const Disclaimer = () => {
  const { setTitle } = useContext(ShellContext)

  useEffect(() => {
    setTitle('disclaimer')
  }, [setTitle])

  return (
    <Box className="Disclaimer max-w-3xl mx-auto p-4">
      <MuiMarkdown>
        {`
the service is provided 'as is', with users bearing complete accountability for their participation

everyone is free to independently audit the [source code](https://github.com/ez-lol/chat) and verify that it's the actual code live on this webpage, ensuring transparency and trust

developers are absolved of any liabilities, guarantees, or repercussions arising from its use
`}
      </MuiMarkdown>
    </Box>
  )
}
